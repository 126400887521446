// extracted by mini-css-extract-plugin
export var blackWrapper = "styles-module--black-wrapper--ghj-j";
export var styleLogo = "styles-module--style-logo--UNMQ0";
export var styleTitle = "styles-module--style-title--+D-th";
export var styleCopies = "styles-module--style-copies--FDM9l";
export var panels = "styles-module--panels--GGU6M";
export var panelsLayout = "styles-module--panels-layout--N9ROd";
export var panelsUpper = "styles-module--panels-upper--pGo46";
export var panelsLower = "styles-module--panels-lower--EZGjX";
export var panelsTitle = "styles-module--panels-title--XATBI";
export var panelsDescription = "styles-module--panels-description--iLZry";
export var panelsBazar = "styles-module--panels-bazar--bZlHm";
export var panelsProgram = "styles-module--panels-program--BvGdM";
export var panelsActivities = "styles-module--panels-activities--vWgU0";
export var library = "styles-module--library--uCsqg";
export var libraryBorder = "styles-module--library-border--9-q3A";
export var libraryTitle = "styles-module--library-title--yvbYL";
export var libraryMargin = "styles-module--library-margin--MQ3Ng";