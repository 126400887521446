import React from 'react'

// Styles
import {
  dateDay,
  dateNumber,
  dateContainer,
} from './styles.module.scss'

interface Props {
  day: string
  month: string
  number: number
}

const DateWrapper: React.FC<Props> = ({ day, month, number }) => {
  return (
    <div className={dateContainer}>
      <div className={`${dateDay} font-avenir`}>{day}</div>
      <div className={`${dateNumber} font-avenir`}>{number}</div>
      <div className={`${dateDay} font-avenir`}>{month}</div>
    </div>
  )
}

export default DateWrapper

