import React from 'react'

// Styles
import { styleButton } from './styles.module.scss'

interface Props {
  text: string
  className?: string
  handleClick: () => void
}

const FICCO2022Button: React.FC<Props> = ({ text, handleClick, className }) => {
  return (
    <button
      className={`${styleButton} font-avenir ${className ? className : ''}`}
      onClick={handleClick}
    >
      {text}
    </button>
  )
}

export default FICCO2022Button
