import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Helmet } from 'react-helmet'

// Components
import DateWrapper from '../../atoms/DateWrapper'
import FICCO2022Button from '../../atoms/FICCO22Button'

// Hooks
import useWindowSize from '../../../hooks/windowSize'

// Styles
import {
  styleLogo,
  styleTitle,
  styleCopies,
  blackWrapper,
  panels,
  panelsTitle,
  panelsUpper,
  panelsLower,
  panelsBazar,
  panelsLayout,
  panelsProgram,
  panelsActivities,
  panelsDescription,
  library,
  libraryTitle,
  libraryMargin,
  libraryBorder,
} from './styles.module.scss'
import Footer from '../../atoms/Footer'

const Home: React.FC = () => {
  const { width } = useWindowSize()
  return (
    <>
      <Helmet>
        <title>FICCO 2022</title>
        <meta
          name="description"
          content="Bienvenido al Festival Independiente de Comic Colombiano 2022. Un evento para artistas y autores de cómic nacional para reunirse, exhibir y aprender del noveno arte..."
        />
        <meta
          name="keywords"
          content="festival, webcomic, comic, evento, fantasia, magia, realismo magico, colombia, biblioteca virgilio barco, colombiano, homepage"
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <div className={`block-wrapper ${blackWrapper} relative`}>
        <div className="container relative">
          <img
            className={panelsLayout}
            src="https://ficcofestival.com/img/assets/FICCO2022Layout.png"
          />
          <div className={panels}>
            <div className={panelsUpper}>
              <div className={`${panelsTitle}`}>
                <img
                  src={
                    width < 640
                      ? 'https://ficcofestival.com/img/logos/FICCO22-Full-Color.png'
                      : 'https://ficcofestival.com/img/logos/FICCO22-Yellow.png'
                  }
                  className={styleLogo}
                />
              </div>
              <div className={panelsDescription}>
                <p className={`${styleTitle} font-subscriber`}>
                  UN EVENTO PARA ARTISTAS Y AUTORES DE CÓMIC NACIONAL DONDE SE
                  PUEDEN REUNIR, EXHIBIR Y APRENDER DEL NOVENO ARTE
                </p>
                <div className={library}>
                  <DateWrapper day={'VIERNES'} month={'DE MAYO'} number={20} />
                  <div className={`${libraryMargin} font-avenir`}>-</div>
                  <DateWrapper day={'DOMINGO'} month={'DE MAYO'} number={22} />
                  <div className={`${libraryMargin} font-avenir`}>EN</div>
                  <div className={`${libraryBorder} font-avenir`}>
                    <div className={libraryTitle}>BIBLIOTECA</div>
                    <div>VIRGILIO BARCO</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={panelsLower}>
              <div className={panelsBazar}>
                <div className={`${styleCopies} font-subscriber`}>
                  ¡Conoce a nuestros invitados especiales!
                </div>

                <FICCO2022Button
                  text={'¡CONOCE MÁS AQUÍ!'}
                  handleClick={() => {
                    navigate('/invitados')
                  }}
                />
              </div>
              <div className={panelsProgram}>
                <div className={`${styleCopies} font-subscriber`}>
                  ¡TENEMOS MUCHAS ACTIVIDADES PLANEADAS!
                </div>
                <FICCO2022Button
                  text={'¡CONOCELAS AQUÍ!'}
                  handleClick={() => {
                    navigate('/programa')
                  }}
                />
              </div>
              <div className={panelsActivities}>
                <div className={`${styleCopies} font-subscriber`}>
                  ¡RECIBIMOS MUCHOS FAN ART INCREIBLES!
                </div>
                <FICCO2022Button
                  text={'¡MIRALOS AQUÍ!'}
                  handleClick={() => {
                    navigate('/fanart')
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home
